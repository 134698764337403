import axios, { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { z, ZodSchema } from 'zod';

import { useMutation } from '@tanstack/react-query';

export type IOtpSendRequest = {
  phone: string;
};

export const otpSendSchema: ZodSchema<IOtpSendRequest> = z.object({
  phone: z.string().min(1, { message: t('forms.required') }),
});
export function useOtpSend() {
  return useMutation<AxiosResponse, AxiosError, IOtpSendRequest>({
    mutationFn: (params) => axios.post('/user-service/otp/sms', undefined, { params }),
  });
}
